<template>
  <div class="best-works-wrap h-100">
    <div class="container best-works">
      <div class="best-works-title flex flex-column cen-center">
        <h1 class="title">{{ "精彩案例" }}</h1>
        <div>专业编辑，精准选曲</div>
      </div>
      <div class="best-works-con">
        <caseList :data="caseList"></caseList>
        <moreBox @handleMore="handleMore"></moreBox>
      </div>
    </div>
  </div>
</template>

<script>
import CaseList from "@/pages/home/components/CaseList.vue";
import moreBox from "@/components/moreBox";

export default {
  data() {
    return {
      caseList: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  components: {
    CaseList,
    moreBox,
  },

  NgImagecreated() {},
  created() {},
  methods: {
    handleMore() {},
  },
};
</script>

<style lang="scss" >
// mobile
// @media screen and (max-width: $screen-width-md) {
.best-works-wrap {
  width: 100%;
  height: 12rem;
  // padding-bottom: 3.2rem;
  background: rgba(0, 0, 0, 0.02);
  .best-works {
    .best-works-title {
      display: flex;
      height: 12rem;

      .title {
        // width: 900px;
        margin-bottom: 0.8rem;
        // font-size: 3.2rem;
      }
    }
  }
  .best-works-con {
      padding-bottom: 3.2rem;
    .music-item {
    //   margin-top: 3.2rem;
    }
    .more-box-wrap {
    //   margin-top: 3.2rem;
    }
  }
}

// pc
@media screen and (min-width: $screen-width-md) {
    .best-works-con {
    .music-item {
      margin-top: 3.2rem;
    }
    .more-box-wrap {
      margin-top: 3.2rem;
    }
  }
}
</style>

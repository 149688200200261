<template>
  <div class="more-box-wrap w-100 flex cen-center" @click="handleMore">
    <span>查看更多</span>
    <div class="more-icon-box flex cen-center">
      <svg class="iconfont">
        <use xlink:href="#iconarrow-line-bottom" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleMore() {
      this.$emit("handleMore");
    },
  },
};
</script>
<style lang="scss" scoped>
// mobile

@media screen and (max-width: $screen-width-md) {
  .more-box-wrap {
    // margin-top: 2.4rem;
    cursor: pointer;
    .more-icon-box {
      font-size: 0.8rem;
      color: #fff;
      width: 3.6rem;
      height: 3.6rem;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50% 50%;
      margin-left: 0.8rem;
    }
  }
}
// pc
@media screen and (min-width: $screen-width-md) {
  .more-box-wrap {
    margin-top: 24px;
    cursor: pointer;
    .more-icon-box {
      font-size: 8px;
      color: #fff;
      width: 36px;
      height: 36px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50% 50%;
      margin-left:8px;
    }
  }
}
</style>
<template>
  <a-modal
    title="Title"
    dialogClass="vedio-dialog"
    :visible="videoDialog"
    :keyboard="false"
    :maskClosable="false"
    :footer="null"
    :closable="false"
  >
    <div class="close-wrap" @click="onClose">
      <div class="close-btn ft8">
        <svg class="iconfont">
          <use xlink:href="#iconclose" />
        </svg>
      </div>
    </div>
    <video ref="videoEl" :src="videoUrl" class="video" controls autoplay />
  </a-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import { Modal } from "ant-design-vue";

export default {
  data() {
    return {
      show: false,
      confirmLoading: false,
      loading: false,
      allPrice: "",
      submitingCart: false,
      allFieldFinised: false,
    };
  },

  components: {
    AModal: Modal,
  },

  props: {
    videoUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["videoDialog"]),
    getSrc() {
     
      return '';
    }
  },
  mixins: [],

  methods: {
    ...mapMutations(["changeVideoDialog"]),

    onClose() {
      this.$refs.videoEl.pause();
      this.changeVideoDialog(false);
    },
    closeModal() {
      this.showCartDialog(false);
    },
  },
};
</script>
<style lang="scss">
// .dialog-contact-us{
//   .ant-drawer-content-wrapper{
//     height: 23.7rem!important;
//   }
// }
</style>
<style lang="scss" scoped>
/deep/ {
  .vedio-dialog {
    top: 20rem;
    padding: 0;
    position: relative;
    .close-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 50% 50%;
      position: absolute;
      right: 0;
      top: -4.2rem;
      cursor: pointer;
      .close-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 0.8rem;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50% 50%;
      }
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
    }
    .ant-modal-body {
      padding: 0;
    }
    .video {
      width: 100%;
      height: 100%;
      //   border-radius: 8px;
    }
  }
}
</style>
